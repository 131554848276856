import React, { useState } from 'react';
import { MegaMenu, Navbar } from 'flowbite-0.10.1';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import wecity_logo_main from '../../images/wecity_logo.svg';
import UserProfile from './UserProfile';
import Registration from './Registration';
import {
  CatalogRoute, LandingRoute, MyOfferingsOverviewRoute, MySubscriptionsOverviewRoute,
} from '../Routes';
import useDefaultDataMarketOrganization from '../hooks/useDefaultDataMarketOrganization';

type MegaMenuItem = {
  label: string;
  disabled?: boolean;
  items: {
    label: string;
    href: string;
    requiredsAuth?: boolean;
  }[];
};

function TopbarMenu() {
  const [subMenuIndex, setSubMenuIndex] = useState<number | null>(null);
  const { idTokenPayload } = useOidcIdToken();
  const defaultOrgId = useDefaultDataMarketOrganization();

  // This should be refactored later:
  const megaMenuData: MegaMenuItem[] = [
    {
      label: 'Shop',
      items: [
        { label: 'Catalogus', href: CatalogRoute() },
        { label: `Mijn producten${(` (${defaultOrgId})`) || ''}`, href: MySubscriptionsOverviewRoute(defaultOrgId) },
        { label: `Mijn aanbod${(` (${defaultOrgId})`) || ''}`, href: MyOfferingsOverviewRoute(defaultOrgId) },
      ],
    },
    {
      label: 'Data tools',
      disabled: true,
      items: [
        { label: 'Mijn API\'s', href: CatalogRoute() },
        { label: 'Webservice bouwer', href: CatalogRoute() },
        { label: 'Aggregeren', href: CatalogRoute() },
        { label: 'Harmoniseren', href: CatalogRoute() },
        { label: 'Semantiseren', href: CatalogRoute() },
        { label: 'DPIA', href: CatalogRoute() },
      ],
    },
    {
      label: 'Dashboard',
      disabled: true,
      items: [
        { label: 'Apps', href: CatalogRoute() },
        { label: 'Rapporten', href: CatalogRoute() },
        { label: 'KPI\'s', href: CatalogRoute() },
      ],
    },
    {
      label: 'Service',
      disabled: true,
      items: [
        { label: 'Contracten', href: CatalogRoute() },
        { label: 'Relationeel assetbeheer', href: CatalogRoute() },
        { label: 'Financiën', href: CatalogRoute() },
      ],
    },
    {
      label: 'Activiteiten',
      disabled: true,
      items: [
        { label: 'Berichten', href: CatalogRoute() },
        { label: 'Acties', href: CatalogRoute() },
        { label: 'Logboek', href: CatalogRoute() },
        { label: 'Monitoring', href: CatalogRoute() },
        { label: 'Rules', href: CatalogRoute() },
        { label: 'Triggers', href: CatalogRoute() },
        { label: 'Meldingen', href: CatalogRoute() },
      ],
    },
    {
      label: ' Beheer',
      disabled: true,
      items: [
        { label: 'Gebruikers', href: CatalogRoute() },
        { label: 'Organisatie', href: CatalogRoute() },
        { label: 'Groepen', href: CatalogRoute() },
      ],
    },
  ];

  const toggleActiveSubMenu = (index: number) => {
    if (subMenuIndex === index) {
      setSubMenuIndex(null);
    } else {
      setSubMenuIndex(index);
    }
  };

  return (
    <div className="bg-white">
      <MegaMenu
        className="mx-auto sm:px-0 pb-0"
      >
        <div className="max-w-screen-xl flex mx-auto grow items-center h-20">
          <Navbar.Brand href={LandingRoute()}>
            <img src={wecity_logo_main} className="h-10" alt="WeCity Logo" />
          </Navbar.Brand>
          <nav className="grow mx-10 h-full flex pt-3">
            {
              megaMenuData.map((item, index) => (
                <button disabled={item.disabled} type="button" key={item.label} onClick={() => toggleActiveSubMenu(index)} className={`grow rounded-t-xl ${index === subMenuIndex ? 'bg-gray-100' : ''}`}>
                  <div className={`m-auto font-bold ${item.disabled ? 'text-gray-300' : 'text-gray-600'} `}>{item.label}</div>
                </button>
              ))
            }
          </nav>
          <div className="flex gap-10">
            {!idTokenPayload && <Registration />}
            <UserProfile />
          </div>
        </div>
        {
          subMenuIndex !== null && (
            <div className="w-full flex justify-left bg-gray-100 p-5">
              <ul className="mx-auto max-w-screen-xl flex justify-left grow gap-10">
                {
                  megaMenuData[subMenuIndex].items.map((item) => (
                    <Navbar.Link className="hover:underline" key={item.label} href={item.href}>{item.label}</Navbar.Link>
                  ))
                }
              </ul>
            </div>
          )
        }
      </MegaMenu>
    </div>
  );
}

export default TopbarMenu;
