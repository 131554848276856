import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import ProductCard from '../library/ProductCard';
import {
  DataProductPlanSubscription,
  useGetSubscriptionsQuery,
} from '../../generated/gql/types';
import MySubscriptionsHero from './MySubscriptionsHero';
import { MyProductSubscriptionRoute } from '../Routes';
import { WeCityOrganizationIdHeader } from '../../Global';
import validateOdps from '../../Helpers';

function MySubscriptionsOverview() {
  const params = useParams();
  const { data, loading } = useGetSubscriptionsQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  function renderSubscription(subscription: Partial<DataProductPlanSubscription>) {
    if (subscription.product) {
      const odps = validateOdps(subscription.product.openDataProductSpecification);

      if (odps) {
        return (
          <Fragment key={`mysubscription-${subscription.id}`}>
            <ProductCard
              odps={odps}
              productRoute={MyProductSubscriptionRoute(params.orgId, subscription.id)}
              subscription={subscription}
            />
          </Fragment>
        );
      }
    }

    return '';
  }

  return (
    <>
      <MySubscriptionsHero />
      <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100">

        {(!loading) ? (
          <div className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 lg:w-full break-inside-avoid-column">
            {data?.dataMarket.subscriptions?.map((subscription) => (
              renderSubscription(subscription)
            ))}
          </div>
        ) : (
          <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default MySubscriptionsOverview;
